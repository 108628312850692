import cloneDeep from "lodash/cloneDeep";
import { segmentEvents } from "@/src/constants/segmentEvents";
import { MiddlewareFunction } from "@segment/analytics-next";

export const segmentPageTitleParserMiddleWare: MiddlewareFunction = ({
  payload,
  next,
}) => {
  const newPayload = cloneDeep(payload);
  const eventType = payload.type();
  if (eventType === "page" || eventType === "track") {
    const pageTitle = payload.obj.context?.page?.title;
    const [pageType, pageCategory] = (pageTitle ?? "|").split("|");
    // Adds page_type and page_category properties for all track and page events
    if (!pageType || !pageCategory) {
      console.error("Segment: Event aborted, missing meta data in page head.");
      // Aborted to prevent contamination of downstream tools with improper event structure format
      // Need to add page meta data to root of page, example:
      // <Head>
      //   <title>{pageMetaData.distribution.title}</title>
      // </Head>
      return;
    }
    newPayload.obj.properties = {
      ...payload.obj.properties,
      page_type: pageType?.trim(),
      page_category: pageCategory?.trim(),
    };

    if (eventType === "page") {
      // rename event name for page calls
      newPayload.obj.name = segmentEvents.pageViewed;
    }
  }
  next(newPayload);
};

export const segmentDefaultPropertiesAppendMiddleWare: ({
  context,
  properties,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties?: Record<string, any>;
}) => MiddlewareFunction =
  ({ context, properties }) =>
  ({ payload, next }) => {
    const newPayload = cloneDeep(payload);
    newPayload.obj.context = {
      ...payload.obj.context,
      ...context,
    };
    newPayload.obj.properties = {
      ...payload.obj.properties,
      ...properties,
    };
    next(newPayload);
  };
