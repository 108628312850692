import { MappedUser, SegmentUser } from "@/src/types/user";
import { z } from "zod";
import { EnvEnum } from "@/src/enums/common";
import type {
  AnalyticsBrowserSettings,
  Callback,
} from "@segment/analytics-next";
import { AnalyticsBrowser } from "@segment/analytics-next";
import type {
  AliasParams,
  EventParams,
  IdentifyParams,
  PageParams,
} from "@segment/analytics-next/dist/types/core/arguments-resolver";
import { getEnv } from "@/src/utils/environments";
import { segmentEvents } from "@/src/constants/segmentEvents";
import {
  segmentDefaultPropertiesAppendMiddleWare,
  segmentPageTitleParserMiddleWare,
} from "./middlewares";

declare global {
  interface Window {
    mixpanel: {
      cookie: {
        clear(): void;
      };
    };
  }
}

export const VisitorSchema = z.object({
  visitor_id: z.string().optional(), // Device fingerprint for e-catalog association
  os: z
    .object({
      name: z.string().optional(),
      version: z.string().optional(),
    })
    .optional(),
});

export type DeviceTraits = z.infer<typeof VisitorSchema>;

interface SegmentCredentials {
  apiHost: string | undefined;
  writeKey: string | undefined;
  cdnUrl: string | undefined;
}

const SegmentAnalytics = () => {
  const segmentClient = new AnalyticsBrowser(); // This loads a buffered version
  let writeKey: string | undefined;
  let userProperties: SegmentUser | undefined;
  let mappedUser: MappedUser | undefined;
  let isInitiated = false;

  const reset = () => {
    segmentClient?.reset();
    window.mixpanel?.cookie.clear(); // Clear mixpanel cookie since segment reset does not call mixpanel
  };

  const loadSegmentClient = (
    credentials: SegmentCredentials & { writeKey: string }
  ) => {
    segmentClient.addSourceMiddleware(segmentPageTitleParserMiddleWare);
    const config: AnalyticsBrowserSettings = {
      writeKey: credentials.writeKey,
      ...(credentials?.cdnUrl && {
        cdnURL: credentials.cdnUrl,
      }),
    };
    segmentClient?.load(config, {
      obfuscate: true,
      integrations: {
        ...(credentials?.cdnUrl &&
          credentials?.apiHost && {
            "Segment.io": {
              apiHost: credentials.apiHost,
              protocol: "https",
            }, // For CDN proxy
          }),
      },
    });
  };

  /**
   * Set default properties to be passed to all events via middleware
   * @param context data for Context field
   * @param properties data for Properties field
   */
  const setDefaultProperties = ({
    context,
    properties,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context?: Record<string, any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties?: Record<string, any>;
  }) => {
    segmentClient.addSourceMiddleware(
      segmentDefaultPropertiesAppendMiddleWare({ context, properties })
    );
  };

  const setUserProperties = ({
    user,
    visitorId,
  }: {
    user?: MappedUser;
    visitorId?: string;
  }) => {
    userProperties = {
      ...userProperties,
      ...(visitorId && { visitor_id: visitorId }),
      ...(user?.supplierId && { supplier_id: user.supplierId }),
      ...(user?.name && { contact_name: user.name }),
      ...(user?.email && { email: user.email }),
      ...(user?.roleAlias && { role: user.roleAlias }),
      ...(user?.accountType && { account_type: user.accountType }),
      ...(user?.supplierName && { company_legal_name: user.supplierName }),
      ...(user?.skuSegmentSizing && { supplier_sku: user.skuSegmentSizing }),
      ...(user?.tier && { supplier_tier: user.tier }),
      ...(user?.supplierCountry && { supplier_country: user.supplierCountry }),
      ...(user?.customerSegment && { supplier_segment: user.customerSegment }),
    };
    mappedUser = user;
    return userProperties;
  };

  /**
   * Gets anonymous id and returns undefined if not found
   * @returns anonymousId
   */
  const getAnonymousId = async () => {
    if (segmentClient?.instance && isInitiated) {
      return (await segmentClient.user()).anonymousId() as string;
    }
    return undefined;
  };

  /**
   * Initiate only if segmentClient is undefined OR input writeKey is different
   */
  const initiate = async (
    credentials: SegmentCredentials,
    onLoadCb?: () => void
  ) => {
    const { writeKey: apiKey, apiHost, cdnUrl } = credentials;
    if (apiKey && apiKey !== writeKey && !isInitiated) {
      isInitiated = true; // Setting isInitiated outside of ready call to ensure initiate method is only called once per root component lifetime.
      loadSegmentClient({ writeKey: apiKey, apiHost, cdnUrl });

      // ready cb will only fire once all destinations are loaded.
      // Will not invoke if destination throws error like invalid api key etc.
      await segmentClient.ready(() => {
        if (getEnv() !== EnvEnum.PRODUCTION) {
          // eslint-disable-next-line no-console
          console.log("segment initiated");
        }
        if (onLoadCb) {
          onLoadCb();
        }
      });
    }
  };

  // ====================================
  // Modified core segment event methods
  // ====================================

  // External delay function instead of using segment's native delay call
  // because the library will not fire the callback in
  // the event that the event fails to deliver.
  // See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/troubleshooting/#if-analyticsjs-fails-to-load-are-callbacks-not-fired

  const eventCallbackWithDelay = async (cb?: Callback) => {
    if (cb && typeof cb === "function") {
      await new Promise((res) => {
        setTimeout(res, 300);
      });
      const callback = cb as () => void;
      callback();
    }
  };

  /**
   * Sends a track event to segment
   * @param args
   */
  const track = (...[eventName, properties, options, cb]: EventParams) => {
    segmentClient?.track(eventName, properties, options);
    eventCallbackWithDelay(cb);
  };

  /**
   * Sends a page event to segment
   * @param args
   */
  const page = (...[category, name, properties, options, cb]: PageParams) => {
    segmentClient?.page(category, name, properties, options);
    eventCallbackWithDelay(cb);
  };

  /**
   * Sends an identify event to segment.
   * @param args
   */
  const identify = (...[id, traits, options, cb]: IdentifyParams) => {
    segmentClient?.identify(id, traits, options);
    eventCallbackWithDelay(cb);
  };

  /**
   * Sends an alias call to segment. Mainly used for destinations that requires alias like Mixpanel.
   * @param args
   */
  const alias = (...[to, from, options, cb]: AliasParams) => {
    segmentClient?.alias(to, from, options);
    eventCallbackWithDelay(cb);
  };

  /**
   * Sends a group call to segment.
   * @param args
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const group = (traits: object) => {
    segmentClient?.group(userProperties?.supplier_id, {
      ...traits,
      company_legal_name: mappedUser?.supplierName,
      account_type: mappedUser?.accountType,
      supplier_sku: mappedUser?.skuSegmentSizing,
      supplier_tier: mappedUser?.tier,
      supplier_segment: mappedUser?.customerSegment,
      supplier_create_date: mappedUser?.supplierCreatedAt,
    });
  };

  // =========================
  //   Custom event methods
  // =========================
  /**
   * Sends login event to segment. Consist of identify call and a track call.
   * @param user
   */
  const login = async ({
    user,
    resetPassword,
    remember,
  }: {
    user: MappedUser;
    resetPassword?: boolean;
    remember?: boolean;
  }) => {
    // Setup user related event middlewares
    const userProps = setUserProperties({ user });
    setDefaultProperties({ properties: userProperties });

    // Event calls
    await segmentClient.identify(user.id, userProps); // set user properties as default TRAITS
    await segmentClient.group(user.supplierId, {
      company_legal_name: user?.supplierName,
      account_type: user?.accountType,
      supplier_sku: user?.skuSegmentSizing,
      supplier_tier: user?.tier,
      supplier_segment: user?.customerSegment,
      supplier_create_date: user?.supplierCreatedAt,
    });
    await segmentClient.track(segmentEvents.loginSucceeded, {
      reset_password: resetPassword || undefined,
      remember_me: !!remember,
    });
  };

  /**
   * Sends logout event and resets segment and mixpanel cookie.
   */
  const logout = async (cb?: () => void) => {
    segmentClient?.track(segmentEvents.logout);
    eventCallbackWithDelay(cb);
    reset();
  };

  /**
   * Sends register event to segment. Will call login event at the end.
   * @param user
   */
  const register = async (user: MappedUser, remember?: boolean) => {
    // Setup user related event middlewares
    setUserProperties({ user });
    setDefaultProperties({ properties: user });

    // Event calls
    await segmentClient?.alias(user.id); // Must be before identify, this is only for destinations that requires aliasing like Mixpanel
    await segmentClient.identify(user.id, userProperties); // set user properties as default TRAITS
    await segmentClient.group(user.supplierId, {
      company_legal_name: user?.supplierName,
      account_type: user?.accountType,
      supplier_sku: user?.skuSegmentSizing,
      supplier_tier: user?.tier,
      supplier_segment: user?.customerSegment,
      supplier_create_date: user?.supplierCreatedAt,
    });
    await segmentClient?.track(segmentEvents.registrationSucceeded, {
      user_id: user.id,
    });
    await new Promise((res) => {
      setTimeout(res, 300);
    });
    // adding 300ms delay to ensure events have some buffer time to fire.
    // Based on testing, no delays seems to result in high failure rates.
    await segmentClient?.track(segmentEvents.loginSucceeded, {
      user_id: user.id,
      first_login: true,
      first_login_timestamp: Date.now(),
      remember_me: !!remember,
    });
  };

  return {
    initiate,
    login,
    register,
    logout,
    track,
    page,
    identify,
    alias,
    group,
    setDefaultProperties,
    setUserProperties,
    userProperties,
    getAnonymousId,
    isInitiated,
  };
};

export const segmentClient = SegmentAnalytics();
