export enum LocaleEnum {
  EN_SG = "EN_SG",
  ZH_CN = "ZH_CN",
  KO_KR = "KO_KR",
  JA_JP = "JA_JP",
  TH_TH = "TH_TH",
  BI = "BI", // Bahasa Indonesia
  ZH_HK = "ZH_HK", // Traditional Chinese - Hong Kong
  ZH_TW = "ZH_TW", // Traditional Chinese - Taiwan
}

export enum UserLanguage {
  EN = "en",
  JP = "jp",
  KO = "ko",
  TH = "th",
  ZH = "zh",
}

export enum EnvEnum {
  DEV = "dev",
  QA = "qa",
  STAGING = "staging",
  PRODUCTION = "prod",
}

export enum ClientCookieKey {
  appSideMenuCollapsed = "appSideMenuCollapsed",
  appSideMenuOpenKeys = "appSideMenuOpenKeys",
}

export enum LocalStorageKey {
  ProductTableColumns = "productTableColumns",
  GoogleCategory = "googleCategory",
  CountryList = "countryList",
  CurrencyList = "currencyList",
  PdpImageAssetsTour = "pdpImageAssetsTour",
  SmartImport = "smartImport",
  LocaleProductDetailEditInfo = "localeProductEditInfo",
  LocaleProductTableColumns = "localeProductTableColumns",
}

export enum SupportedDateFormatsEnum {
  "DD/MM/YYYY" = "DD/MM/YYYY",
  "MM/DD/YYYY" = "MM/DD/YYYY",
  "YYYY/MM/DD" = "YYYY/MM/DD",
}

export enum SupportedTimezonesEnum {
  "UTC +01:00" = "Africa/Algiers",
  "UTC +02:00" = "Europe/Paris",
  "UTC +03:00" = "Europe/Moscow",
  "UTC +04:00" = "Asia/Dubai",
  "UTC +05:00" = "Asia/Karachi",
  "UTC +06:00" = "Asia/Dhaka",
  "UTC +07:00" = "Asia/Jakarta",
  "UTC +08:00" = "Asia/Kuala_Lumpur",
  "UTC +09:00" = "Asia/Tokyo",
  "UTC +10:00" = "Australia/Brisbane",
  "UTC +11:00" = "Asia/Magadan",
  "UTC +12:00" = "Pacific/Auckland",
  "UTC +13:00" = "Pacific/Apia",
  "UTC +14:00" = "Pacific/Kiritimati",
  "UTC -01:00" = "Atlantic/Cape_Verde",
  "UTC -02:00" = "America/Noronha",
  "UTC -03:00" = "America/Recife",
  "UTC -04:00" = "America/Toronto",
  "UTC -05:00" = "America/Winnipeg",
  "UTC -06:00" = "America/Guatemala",
  "UTC -07:00" = "America/Los_Angeles",
  "UTC -08:00" = "America/Anchorage",
  "UTC -09:00" = "Pacific/Gambier",
  "UTC -10:00" = "Pacific/Honolulu",
  "UTC -11:00" = "Pacific/Pago_Pago",
}
